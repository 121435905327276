/* Just a list of helper functions */

export const addSpace = (name) => (name.toString().replace(/_/g, " "))

export const modularScale = (number) => {
	const scaleRatio = 1.25;
	const baseline = 1; //1 rem

	let result = baseline;

	if(number > 0) {
		for(let i = 0; i < number; i++){
			result *= scaleRatio;
		}

	} else if(number < 0) {
		for(let i = 0; i > number; i--){
			result /= scaleRatio;
		}
	}
  
	return((Math.round(result * 100) / 100).toString() + 'rem');
}

export const screenSizes = {sm: 550, md: 800, lg: 1200};

export const breakpoints = (
	Object.keys(screenSizes).reduce((acc, key) => ({ 
		...acc, [key]: `@media (max-width: ${screenSizes[key]}px)` 
	}),{})
)